<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        Ad feed details ({{ item.feed_item_count }} feeds)
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field v-model="item.campaign_name" label="Campaign" disabled></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="item.topic" label="topic" disabled></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="item.gender" label="Gender" disabled></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="item.age_group" label="Age group" disabled></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="item.match_type" label="Match type" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="item.headline1" label="Headline 1" required :rules="[v => !!v || 'Headline 1 is required']" counter :maxlength="maxHeadlineLength"></v-text-field>
                <v-text-field v-model="item.headline2" label="Headline 2" required :rules="[v => !!v || 'Headline 2 is required']" counter :maxlength="maxHeadlineLength"></v-text-field>
                <v-text-field v-model="item.headline3" label="Headline 3" required :rules="[v => !!v || 'Headline 3 is required']" counter :maxlength="maxHeadlineLength"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="item.description_line1" label="Description line 1" required :rules="[v => !!v || 'Description line 1 is required']" counter :maxlength="maxDescriptionLength"></v-text-field>
                <v-text-field v-model="item.description_line2" label="Description line 2" required :rules="[v => !!v || 'Description line 2 is required']" counter :maxlength="maxDescriptionLength"></v-text-field>
                <v-text-field v-model="item.path1" label="Path 1" required :rules="[v => !!v || 'Path 1 is required']" counter :maxlength="maxPathLength"></v-text-field>
                <v-text-field v-model="item.path2" label="Path 2" required :rules="[v => !!v || 'Path 2 is required']" counter :maxlength="maxPathLength"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Ad customizer' }">Back</v-btn>
        <v-btn color="primary" :loading="saving" @click="saveToAPI()">Change Ads</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Ads changed</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'AdCustomizerAggregatedFeedItemDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        campaign_name: null,
        topic: null,
        gender: null,
        age_group: null,
        match_type: null,
        headline1: null,
        headline2: null,
        headline3: null,
        description_line1: null,
        description_line2: null,
        path1: null,
        path2: null,
        feed_item_count: null
      },
      valid: false,
      loading: false,
      saving: false,
      showSaved: false,
      maxHeadlineLength: 30,
      maxDescriptionLength: 90,
      maxPathLength: 15,
    }
  },

  computed: {
    fetchEndpoint () {
      return '/a/google/aggregated-feed-items/' + this.id
    },

    saveEndpoint () {
      return '/a/google/aggregated-feed-items/' + this.id + '/save-to-adwords'
    },
  },

  mixins: [formsMixin],

  methods: {
    fetchData: function () {
      this.loading = true

      var myURL = this.fetchEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    saveToAPI: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      let saveObj = {
        'headline1': this.item.headline1,
        'headline2': this.item.headline2,
        'headline3': this.item.headline3,
        'description_line1': this.item.description_line1,
        'description_line2': this.item.description_line2,
        'path1': this.item.path1,
        'path2': this.item.path2
      }
      var body = JSON.stringify(saveObj)
      this.$http.put(this.saveEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.saveEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchData()
  },
}
</script>
